'use client';
import React from 'react';
import { usePathname } from 'next/navigation';

import { LinkButton } from '@/components/ui/link-button';

export const NotFoundPage: React.FC = () => {
  const path = usePathname();

  const isMX = path?.includes('/mx');

  if (isMX) {
    return (
      <>
        <section className="flex min-h-screen items-center justify-center bg-primary-40">
          <div className="flex flex-col gap-5 text-center">
            <h1 className="text-5xl font-bold">404</h1>
            <h2 className="text-5xl">¡Ups! Página no encontrada</h2>
            <p>Lo sentimos, no pudimos encontrar la página que estabas buscando.</p>
            <LinkButton href="/mx/" className="bg-black text-white hover:bg-white hover:text-black">
              Vuelve a home
            </LinkButton>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      <section className="flex min-h-screen items-center justify-center bg-primary-40">
        <div className="flex flex-col gap-5 text-center">
          <h1 className="text-5xl font-bold">404</h1>
          <h2 className="text-5xl">Ops! Página não encontrada</h2>
          <p>Desculpe, não conseguimos encontrar a página buscada.</p>
          <LinkButton href="/br/" className="bg-black text-white hover:bg-white hover:text-black">
            Retornar para home
          </LinkButton>
        </div>
      </section>
    </>
  );
};
